import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import GlobalNav from '../../components/global-nav';
import ContentBlock from '../../components/content-block';

export default ({ data }) => {
  return (
    <main className="fxui-main">
      <Helmet>
        <meta charSet="utf-8"/>
        <title>FOX UI Kit</title>
        <link rel="stylesheet" href="/fox-slds-kit/styles/salesforce-lightning-design-system.min.css"/>
      </Helmet>
      <GlobalNav data={data} />
      <section className="fxui-root">
        {data.allMarkdownRemark.edges.map(({node}) => (
          <ContentBlock key={node.fields.slug} node={node}/>
        ))}
      </section>
    </main>
  );
}

export const query = graphql`
query {
  allMarkdownRemark(
    filter: {
      fileAbsolutePath: { regex: "/pages\/slds/" }
    }
    sort: {
      fields: [fileAbsolutePath]
      order:ASC
    }
  ) {
    edges {
      node {
        fields {
          leaf,
          slug
        }
        frontmatter {
          title,
          leaf,
          contentClass
        }
        html
      }
    }
  }
}
`
